import * as React from "react"
import { useQuery, gql } from "@apollo/client"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import CircularProgress from "@material-ui/core/CircularProgress"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import CardActions from "@material-ui/core/CardActions"
import IconButton from "@material-ui/core/IconButton"

import { refreshClosedStoreSettings } from "../contexts/actions"
import { StoreSettingsContext, CartContext } from "../contexts"

import { Link } from "gatsby"

import AddIcon from "@mui/icons-material/ArrowBack"

import { makeStyles } from "@material-ui/core/styles"

import FormContainer from "../components/forms/formContainer"
import fieldStockFilter from "../helperFunc/fieldStockFilter"
import Layout from "../components/ui/layout"
import Seo from "../components/ui/seo"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: "4.0rem",
    marginBottom: "5rem",
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: "40rem",
      marginTop: "6.0rem",
      marginBottom: "2rem",
    },
  },
  boxGrids: {
    padding: 0,
  },
  mainCard: {
    borderRadius: 0,
    [theme.breakpoints.up("md")]: {
      borderRadius: "1rem",
    },
  },
  titleArea: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: "0.4rem",
    paddingBottom: "0.4rem !important",
  },
  titleText: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    color: theme.palette.common.white,
  },

  contentArea: {
    padding: 0,
    paddingBottom: "0 !important",

    backgroundColor: theme.palette.common.white,
  },

  contentText: {
    paddingTop: "1rem",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
    lineHeight: "1.2rem",
  },

  addIcon: {
    zIndex: "10",
    padding: ".5rem",
    top: "40px",
    left: "1.5rem",
    marginRight: "auto",
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.offWhite,
    },
  },
  extraContentArea: {
    backgroundColor: theme.palette.common.offWhite,
  },
  addIconCol: {
    color: theme.palette.primary.main,
  },
  bottomArea: {
    backgroundColor: theme.palette.common.offWhite,
    height: "0px",
    padding: "0px",
  },

  unavailable: {
    zIndex: "9",
    lineHeight: "1.1rem",
    width: "100%",
    textAlign: "center",
    backgroundColor: theme.palette.common.grey,
    padding: "0.5rem",
    color: theme.palette.common.white,
    textTransform: "uppercase",
  },
  imageArea: {
    height: "100%",
    width: "100%",
    WebkitTransform: "scaleX(-1)",
  },
  loading: {
    textAlign: "center",
    paddingTop: "40svh",
  },
}))

const GET_DETAILS = gql`
  query getDeatils($id: ID!) {
    product(id: $id) {
      data {
        id
        attributes {
          sharedFields(pagination: { limit: 100 }) {
            data {
              id
              attributes {
                optionsData(pagination: { limit: 100 }) {
                  data {
                    id
                    attributes {
                      inStock
                    }
                  }
                }
              }
            }
          }
          isVisible
          inStock
        }
      }
    }
  }
`

export default function ProductDetail({
  pageContext,
  data: {
    allStrapiProduct: { edges: productArray },
  },
  location,
}) {
  const [inStock, setInStock] = React.useState(true)
  const [isVisible, setIsVisible] = React.useState(null)
  const [formFieldsData, setFormFieldsData] = React.useState([])
  const [isSavedData, setIsSavedData] = React.useState(null)
  const [savedData, setSavedData] = React.useState({})

  const { storeSettings, dispatchStoreSettings } =
    React.useContext(StoreSettingsContext)

  const { cart } = React.useContext(CartContext)
  const cartRef = React.useRef({})

  cartRef.current = [...cart]

  const classes = useStyles()
  let product = productArray[0].node

  const { error, data } = useQuery(GET_DETAILS, {
    variables: { id: product.strapiId },
  })

  React.useEffect(() => {
    if (location.state?.itemId) {
      let cartIndex = cartRef.current.findIndex(
        element => element.itemId === location.state.itemId
      )
      if (cartIndex > -1) {
        setIsSavedData(true)
        setSavedData(cartRef.current[cartIndex])
        window.history.replaceState(null, null, window.location.pathname)
      } else {
        setIsSavedData(false)
        setSavedData({})
      }
    } else if (location.state?.refresh) {
      window.history.replaceState(null, null, window.location.pathname)
      if (isSavedData) {
        window.location.reload()
      } else {
        setIsSavedData(false)
      }
    } else {
      setIsSavedData(false)
      setSavedData({})
    }
  }, [location, isSavedData])

  React.useEffect(() => {
    let formFields = []
    let optionsSharedFieldFormStock = []
    if (error) {
      setInStock(-1)
    } else if (data) {
      if (data.product.data.attributes.sharedFields) {
        for (
          let i = 0;
          data.product.data.attributes.sharedFields.data.length > i;
          i++
        ) {
          let options = []
          for (
            let jj = 0;
            data.product.data.attributes.sharedFields.data[i].attributes
              .optionsData.data.length > jj;
            jj++
          ) {
            options.push({
              id: data.product.data.attributes.sharedFields.data[i].attributes
                .optionsData.data[jj].id,
              inStock:
                data.product.data.attributes.sharedFields.data[i].attributes
                  .optionsData.data[jj].attributes.inStock,
            })
          }

          optionsSharedFieldFormStock.push({
            id: data.product.data.attributes.sharedFields.data[i].id,
            optionsData: options,
          })
        }
      }

      for (let i = 0; i < product.sharedFields.length; i++) {
        if (!product.sharedFields[i].optionsData) {
          product.sharedFields[i].optionsData = []
        } else {
          //////////////////////TEMP ORDER FIX
          let optionsDataCopy = [...product.sharedFields[i].optionsData]
          optionsDataCopy.sort(function (a, b) {
            return parseInt(a.strapiId) - parseInt(b.strapiId)
          })
          product.sharedFields[i].optionsData = optionsDataCopy
          //////////////////////TEMP ORDER FIX
        }
      }

      let sharedFormFields = fieldStockFilter(
        product.sharedFields,
        optionsSharedFieldFormStock,
        "strapiId"
      )

      sharedFormFields.forEach((field, index) => {
        sharedFormFields[index] = { ...field, isSharedField: true }
      })

      if (sharedFormFields.length > 0) {
        if (product.sharedFieldOrder) {
          for (let i = 0; i < product.sharedFieldOrder.length; i++) {
            for (let j = 0; j < sharedFormFields.length; j++) {
              if (
                product.sharedFieldOrder[i].fieldName ===
                sharedFormFields[j].fieldName
              ) {
                sharedFormFields[j].fieldOrder =
                  product.sharedFieldOrder[i].fieldOrder
                sharedFormFields[j].isVisible =
                  product.sharedFieldOrder[i].isVisible
                if (product.sharedFieldOrder[i].discardPrice) {
                  for (
                    let k = 0;
                    k < sharedFormFields[j].optionsData.length;
                    k++
                  ) {
                    sharedFormFields[j].optionsData[k].optionPrice = 0
                  }
                }

                if (
                  product.sharedFieldOrder[i].isRequired !== null &&
                  product.sharedFieldOrder[i].isRequired !== undefined
                ) {
                  sharedFormFields[j].isRequired =
                    product.sharedFieldOrder[i].isRequired
                }
              }
            }
          }
        }
        formFields.push(...sharedFormFields)
      }
      setFormFieldsData(formFields)
      setInStock(data.product.data.attributes.inStock)
      if (data.product.data.attributes.isVisible === false) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }
  }, [setInStock, error, data, product.sharedFieldOrder, product.sharedFields])

  React.useEffect(() => {
    dispatchStoreSettings(refreshClosedStoreSettings())
  }, [dispatchStoreSettings])

  let imgStyle = inStock
    ? { WebkitTransform: "scaleX(-1)" }
    : {
        WebkitTransform: "scaleX(-1)",
        WebkitFilter: "grayscale(100%)",
        filter: "grayscale(100%)",
      }

  return (
    <Layout>
      <Seo
        title={`${product.name}`}
        description={product.description ? `${product.description}` : null}
      />
      <Container classes={{ root: classes.mainContainer }}>
        <Grid item classes={{ root: classes.boxGrids }}>
          {isVisible === true && isSavedData !== null ? (
            <Card classes={{ root: classes.mainCard }} elevation={0}>
              <CardContent classes={{ root: classes.titleArea }}>
                <Typography variant="h6" classes={{ root: classes.titleText }}>
                  {product.name}
                </Typography>
              </CardContent>

              <CardActions
                disableSpacing
                classes={{ root: classes.bottomArea }}
              >
                <Link to={`${isSavedData === true ? "/cart" : "/menu"}`}>
                  <IconButton
                    aria-label="go to products"
                    classes={{ root: classes.addIcon }}
                    color="secondary"
                  >
                    <AddIcon classes={{ root: classes.addIconCol }} />
                  </IconButton>
                </Link>
              </CardActions>

              <CardMedia
                component={Box}
                style={{
                  backgroundImage: `url(${product.images[0].url})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                  height: "200px",
                  ...imgStyle,
                }}
              >
                {inStock ? (
                  <></>
                ) : (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                    classes={{ root: classes.imageArea }}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        classes={{ root: classes.unavailable }}
                      >
                        {"Unfortunately this item is currently unavailable"}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </CardMedia>

              <CardContent classes={{ root: classes.contentArea }}>
                {product.description ? (
                  <Typography
                    variant="body1"
                    paragraph
                    classes={{ root: classes.contentText }}
                  >
                    {product.description}
                  </Typography>
                ) : (
                  <></>
                )}

                <FormContainer
                  dataIn={formFieldsData}
                  product={product}
                  inStock={inStock}
                  storeSettings={storeSettings}
                  dispatchStoreSettings={dispatchStoreSettings}
                  isSavedData={isSavedData}
                  savedData={savedData}
                ></FormContainer>
              </CardContent>
            </Card>
          ) : isVisible === false && isSavedData !== null ? (
            <>
              <Typography
                variant="body2"
                classes={{ root: classes.unavailable }}
              >
                {"Item not found"}
              </Typography>
            </>
          ) : (
            <Box classes={{ root: classes.loading }}>
              <CircularProgress color="secondary" />
            </Box>
          )}
        </Grid>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query GetProduct($id: Int!) {
    allStrapiProduct(filter: { strapiId: { eq: $id } }) {
      edges {
        node {
          section {
            name
            slug
          }
          description
          showQuantity
          featured
          images {
            url
          }
          name
          price
          sharedFieldOrder
          sharedFields {
            customCssClass
            customValidationMessage
            descriptionVisibility
            fieldDescription
            fieldDigitalDescription
            fieldDigitalLabel
            fieldName
            fieldOrder
            fieldPrice
            fieldPrintDescription
            fieldPrintLabel
            fieldType
            isRequired
            isVisible
            labelVisibility
            maxNumbOfSelections
            defaultOption
            optionsData {
              optionConditions
              optionDescription
              optionDigitalLabel
              optionImage {
                url
              }
              optionName
              optionPrice
              optionPriceInfo
              optionPrintLabel
              id
              strapiId
            }
            placeholder
            unlimitedSelections
            createdAt
            id
            publishedAt
            strapiId
            updatedAt
          }
          category {
            description
            filterOptions
            id
            name
            nodeId
            parentCategory
            publishedAt
            slug
            strapiId
            updatedAt
          }
          createdAt
          id
          publishedAt
          strapiId
          updatedAt
        }
      }
    }
  }
`
